import React, { useState } from "react";
import "./Signup.css"; // Include your CSS file here
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import vedio1 from "../../Pages/Signup/Assets/1.gif"
import logo from "../../Pages/Signup/Assets/logo.mp4"
import vedio2 from "../../Pages/Signup/Assets/vedio1.mp4"
import icon1 from "../../Pages/Signup/Assets/Book.png"
import icon2 from "../../Pages/Signup/Assets/trophy.png"
import logo2 from "../../Pages/Signup/Assets/logo.mp4"


const SignUp = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [awards, setAwards] = useState("");
  const [hasReceivedAwards, setHasReceivedAwards] = useState(null); // Track "Yes" or "No" selection
  const [passedOut, setPassedOut] = useState("");
  const [otherYear, setOtherYear] = useState("");
  const [workDesignation, setWorkDesignation] = useState("");
  const [emailId, setEmailId] = useState("");
  const [employmentStatus, setEmploymentStatus] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [isFormVisible, setIsFormVisible] = useState(false); // State to control form visibility

  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleEmploymentChange = (e) => {
    setEmploymentStatus(e.target.value);
  };

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setPassedOut(value);
    if (value !== "other") {
      setOtherYear(""); // Clear otherYear when not selecting "Other"
    }
  };

  function validateGmail() {
    const emailInput = document.getElementById("emailInput");
    const errorMessage = document.getElementById("errorMessage");
    const emailValue = emailInput.value;

    if (!emailValue.endsWith("@gmail.com")) {
      errorMessage.style.display = "block";
      // If the Gmail is not valid, a custom message will display
      emailInput.setCustomValidity("Please enter a valid Gmail address.");
    } else {
      // If valid, set custom message is empty
      errorMessage.style.display = "none";
      emailInput.setCustomValidity("");
    }
  }

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-lg-12 col-md-12 col-12">
          <div className="sign-container">
            <div className="signup-color logo_image ">
              <div className="  player_wrapper01 text-center">
                <video className="react_player01  " src={logo} loop autoPlay muted>
                  Your browser does not support HTML video.
                </video>
              </div>
              <div className=" signup_H1_P_Content">
                <span>WELCOME TO NOARREARS CLUB</span>
                <p>Become part of the top 1% of high-achieving graduates network</p>
              </div>
              <div >
                <button className="custom-btn btn-11" >
                Sign Up<div className="dot"></div>
                </button>
              </div>

            </div>
          </div>
        </div>

      </div>
      <div className="row ">

        <div className="col-lg-12 d-flex">
          <div className="col-lg-7 col-md-12">
            <div className="player_wrapper001 text-center">
              <video className="react_player001  " src={vedio2} loop autoPlay muted>
                Your browser does not support HTML video.
              </video>
            </div>

          </div>
          <div className="col-lg-5 col-md-12 signup_Content2">
            <h4>Are You an Engineering Graduate , with <br /> a <span>Spotless Acedemic Record </span> with Zero <br />History of Arrears or Backlogs?</h4>
            <h3>Yet, are you still searching for the <br /> recognition you've earned?"</h3>

            <button className="custom-btn btn-12" data-bs-toggle="modal" data-bs-target="#exampleModalSignup"><span> Get Privileged</span><span>Join our elite No-Arrears Club</span></button>



          </div>

        </div>
        <div className="row">
          <div className="col-lg-12 d-flex">
            <div className="col-lg-3 col-md-12 col-12 red_Content1">
              <h5>With a history of <br />
                <p>zero arrears</p></h5>

            </div>
            <div className="col-lg-4 col-md-12 col-12  red_Content2">
              <h5>Join an exclusive club</h5>
              <p>tailored for top achievers like you!</p>
            </div>

            <div className=" col-lg-5 icon_content001 ">
              <div className="icon_1_wrapper">
                <img src={icon1} alt="" className="icon_1" />
              </div >
              <h3>+</h3>
              <div className="player_wrapper01_icon text-center">
                <video className="react_player01_icon  " src={logo2} loop autoPlay muted>
                  Your browser does not support HTML video.
                </video>
              </div>
              <h3>=</h3>
              <div className="icon_2_wrapper">
                <img src={icon2} alt="" className="icon_2" />
              </div>
            </div>


          </div>

        </div>
        <div className="row my-4">
          <div className="col-lg-12 Border_line_under">

          </div>

        </div>
      </div>
      <div className="row">
        <div className="modal fade" id="exampleModalSignup" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h1 className="modal-title fs-5" id="exampleModalLabel">Fill the below Information to become member of   NOARREARS.CLUB</h1>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <div className="signup-card" style={{ position: "relative" }}>
                  <form>
                    <div className="label-float">
                      <input type="text" name="name" required placeholder="Name" />
                      <label>Name</label>
                    </div>

                    <div className="label-float">
                      <input
                        type="email"
                        name="email"
                        required
                        placeholder="Email ID"
                        id="emailInput"
                        onBlur={validateGmail}
                      />
                      <label>Email ID</label>
                      <span
                        id="errorMessage"
                        style={{ color: "red", display: "none" }}
                      >
                        Please enter a valid Gmail address (e.g., example@gmail.com).
                      </span>
                    </div>

                    <div className="label-float">
                      <input
                        type="tel"
                        name="phoneNumber"
                        maxLength="10"
                        required
                        onChange={(e) => {
                          let value = e.target.value.replace(/\D/g, "");
                          if (value.length > 10) {
                            value = value.slice(0, 10);
                          }
                          e.target.value = value;
                        }}
                        placeholder="Mobile Number"
                        onBlur={(e) => {
                          let value = e.target.value;
                          const errorMessage =
                            document.getElementById("error-message");

                          if (value.length < 10) {
                            errorMessage.textContent =
                              "Please enter a valid 10-digit mobile number.";
                          } else {
                            if (!value.startsWith("+91 ")) {
                              e.target.value = `+91 ${value}`;
                            }
                            errorMessage.textContent = "";
                          }
                        }}
                      />
                      <label>Mobile Number</label>
                      <div
                        id="error-message"
                        style={{ color: "red", marginTop: "5px" }}
                      ></div>
                    </div>

                    <div className="label-float">
                      <input
                        type="text"
                        name="degreeName"
                        required
                        placeholder="Degree Name"
                      />
                      <label>Degree Name</label>
                    </div>

                    <div className="label-float">
                      <input
                        type="text"
                        name="collegeName"
                        required
                        placeholder="College Name"
                      />
                      <label>College Name</label>
                    </div>

                    <div className="label-float">
                      <input
                        type="text"
                        name="universityName"
                        required
                        placeholder="University Name"
                      />
                      <label>University Name</label>
                    </div>

                    <div className="label-float">
                      <select
                        name="passedOut"
                        required
                        style={{ width: "98.1%", height: "9vh", borderRadius: "5px" }}
                        value={passedOut}
                        onChange={handleSelectChange}
                      >
                        <option value="" disabled>
                          Select Year
                        </option>
                        {Array.from({ length: 16 }, (_, i) => {
                          const year = 2011 + i;
                          return (
                            <option key={year} value={year}>
                              {year}
                            </option>
                          );
                        })}
                      </select>
                      <label>Passed Out Year</label>
                    </div>

                    <div className="label-float">
                      <select
                        name="employmentStatus"
                        required
                        style={{
                          width: "98.1%",
                          height: "9vh",
                          borderRadius: "5px",
                          marginTop: "10px",
                        }}
                        value={employmentStatus}
                        onChange={handleEmploymentChange}
                      >
                        <option value="" disabled>
                          Select Employment Status
                        </option>
                        <option value="working">Working</option>
                        <option value="notWorking">
                          looking for a job opportunity from No-Arrearsclub
                        </option>
                      </select>
                      <label>Employment Status</label>
                    </div>

                    {employmentStatus === "working" && (
                      <div
                        className="additional-fields"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="label-float">
                          <input
                            type="text"
                            name="Organization"
                            required
                            style={{
                              width: "98.1%",
                              height: "9vh",
                              borderRadius: "5px",
                            }}
                            placeholder="Organization"
                            value={workDesignation}
                            onChange={(e) => setWorkDesignation(e.target.value)}
                          />
                          <label>Organization</label>
                        </div>

                        <div className="label-float">
                          <input
                            type="text"
                            name="workDesignation"
                            required
                            style={{
                              width: "98.1%",
                              height: "9vh",
                              borderRadius: "5px",
                            }}
                            placeholder="Designation"
                            value={workDesignation}
                            onChange={(e) => setWorkDesignation(e.target.value)}
                          />
                          <label>Designation</label>
                        </div>

                        <div className="label-float">
                          <input
                            type="email"
                            name="workEmail"
                            required
                            style={{
                              width: "98.1%",
                              height: "9vh",
                              borderRadius: "5px",
                            }}
                            placeholder="Work Email ID"
                            value={workEmail}
                            onChange={(e) => setWorkEmail(e.target.value)}
                          />
                          <label>Organization Email ID</label>
                        </div>
                      </div>
                    )}

                    <div className="label-float">
                      <input
                        type="text"
                        name="percentage"
                        required
                        placeholder="CGPA/Overall Percentage %"
                      />
                      <label>CGPA/Overall Percentage %</label>
                    </div>

                    <div className="label-float">
                      <select
                        name="arrears"
                        required
                        style={{ width: "98.1%", height: "9vh", borderRadius: "5px" }}
                        defaultValue=""
                      >
                        <option>0</option>
                      </select>
                      <label>History Of Arrears</label>
                    </div>

                    <div className="label-float">
                      <input
                        type="file"
                        name="file"
                        required
                        placeholder="Consolidated Marksheet"
                      />
                      <label>Consolidated Marksheet</label>
                    </div>

                    <div className="label-float">
                      <input
                        type="text"
                        name="awards"
                        value={awards}
                        onChange={(e) => setAwards(e.target.value)}
                        placeholder="Toppers Awards Received"
                        required
                        disabled={hasReceivedAwards !== "yes"} // Disable unless "Yes" is selected
                      />
                      <label>Toppers Awards Received ( if any) </label>
                    </div>

                    <div className="radio-container">
                      <label>
                        <input
                          type="radio"
                          name="hasReceivedAwards"
                          value="yes"
                          checked={hasReceivedAwards === "yes"}
                          onChange={() => setHasReceivedAwards("yes")}
                        />
                        Yes
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="hasReceivedAwards"
                          value="no"
                          checked={hasReceivedAwards === "no"}
                          onChange={() => setHasReceivedAwards("no")}
                        />
                        No
                      </label>
                    </div>

                    <p>
                      By clicking Agree & Join or Continue, you agree to the
                      No-Arrears <a href="#">User Agreement,</a>{" "}
                      <a href="#">Privacy Policy,</a> and{" "}
                      <a href="#">Cookie Policy.</a>
                    </p>

                    <button
                      type="submit"
                      className="Join-button"
                      style={{ borderRadius: "100px" }}
                    >
                      JOIN TO NO-ARREARS CLUB
                    </button>

                    <p style={{ textAlign: "center" }}>or</p>
                  </form>
                  <p style={{ textAlign: "center" }}>
                    Our Team Will Get Back to You Soon <br /> Already Registered{" "}
                    <Link to="/signin">Sign in</Link>
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>

              </div>
            </div>
          </div>
        </div>
      </div>


     

    </div>
  );
};

export default SignUp;
