import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import SignUp from "./Pages/Signup/Signup";



function App() {
  return (
   <>
   <SignUp />
   </>
  );
}

export default App;
